import React from 'react';
import { graphql } from 'gatsby';

import Heading from '@/atoms/Heading';
import Head from '@/components/Head';
import Page from '@/components/Page';
import ModulesLoop from '@/components/ModulesLoop';

export default ({
  data: {
    page: { title, meta, background = 'white', contentModules },
  },
  pageContext: { isFrontpage = false },
}) => {
  // don't use specific page title for frontpage meta
  const pageTitle = isFrontpage ? undefined : title;

  return (
    <>
      <Head title={pageTitle} meta={meta} />

      <Page background={background}>
        {!isFrontpage && (
          <Heading h={1} hide>
            {title}
          </Heading>
        )}

        <ModulesLoop contentModules={contentModules} background={background} />
      </Page>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      background
      meta {
        ...metaData
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`;
